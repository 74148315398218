const writtenContent = {

    "lang": [

        "Francais",

        "English"

    ],

    "ip": [

        "Industrial Projects",

        "Les Projets Industriels"

    ],



    //NAVBAR

    "navHome": ["Home", "Accueil"],

    "navAbout": ["About", "Apercu"],

    "navIndustries": ["Industries", "Industries"],

    "navProducts": ["Products", "Produits"],

    "navContact": ["Contact", "Contact"],



    //FOOTER

    "footer": ["Industrial Projects Limited, All Rights Reserved.", "Industrial Projects Limited, tous droits réservés."],



    //HOME CONTENT

    "carousel1": [

        "Expertise in process performance, safety and system optimization",

        "Expertise en performance des processus, sécurité et optimisation des systèmes"

    ],

    "carousel2": [

        "Meeting the food industry's highest standards with the highest quality products",

        "Répondre aux normes les plus élevées de l'industrie alimentaire avec des produits de la plus haute qualité"

    ],

    "carousel3": [

        "Cost effective safety products for industry",

        "Produits de sécurité rentables pour l’industrie"

    ],

    "carousel4": [

        "State of the art certified safety products for automation",

        "Produits de sécurité certifiés avec technologie de pointe pour l’automatisation"

    ],



    "whoAreWe": ["Who are we?", "Qui sommes nous?"],

    "homeFounded": [

        "Founded in 1958, Industrial Projects Ltd is a well-respected leader in the distribution of pneumatic valves, control reliable and safety valves, filtration systems and instrumentation components in Eastern Canada. \n\nOur product knowledge and technical support resources will assist you in evaluating your requirements and recommend the product that best suits your process needs.",

        "Fondé en 1958, Les Projets Industriels est un chef de file très respecté dans la distribution de vannes pneumatiques, de vannes de contrôle et de sécurité fiables, de systèmes de filtration et d'instrumentation industrielle pour l'Est du Canada. \n\nNotre connaissance des produits et nos ressources de support technique vous aideront à évaluer vos besoins et à vous recommander le produit qui convient le mieux à vos exigences de procédés."

    ],

    "homeCard1": ["New hydraulic safety valves", "Nouvelles valves hydrauliques de sécurité"],

    "homeCard1Body": [

        "ROSS Controls has developed a revolutionary block-bleed and block-hold hydraulic safety system to suit the most hazardous environments.",

        "ROSS Controls a développé un système révolutionnaire de sécurité hydraulique à blocage et à purge pour répondre aux environnements les plus dangereux."

    ],

    "homeCard2": ["Rosedale's NCO & NCLO Housings", "Boîtier de filtration NCO & NCLO de Rosedale"],

    "homeCard2Body": [

        "The NCO and NCLO housings supplied by Rosedale are a low cost filter housing solution that allow 100gpm flow rates. These exceptionally valued housings are rated for up to 150psi.",

        "Les NCO et NCLO fournis par Rosedale sont des solutions de boîtiers à filtre à coût réduit qui permettent des débits de 100 gpm. Ces boîtiers d'une valeur exceptionnelle sont conçus pour résister à des pressions allant jusqu'à 150psi."

    ],

    "homeCard3": ["Parker's Coverage", "Couverture de Parker"],

    "homeCard3Body": [

        "We supply all Parker filter cartridges and housings, ranging from Avasan to pharmaceutical grade products. This catalogue coverage can supply your entire design process. Contact us for any inquiries you may have.",

        "Nous fournissons tous les filtres et boîtiers Parker, allant de l’Avasan aux produits de qualité pharmaceutique. Ce catalogue peut couvrir l'ensemble de votre processus de conception. Contactez-nous pour toute demande de renseignements."

    ],

    "icon1": ["Quality", "Qualité"],

    "icon2": ["Performance", "Performance"],

    "icon3": ["Dependability", "Fiabilité"],

    "parkerBanner": [

        "For 30 years, Parker has partnered with Industrial Projects to bring an all encompassing range of cartridges and housings to the wider Eastern Canadian marketplace. Parker provides cartridge filters from 150 microns to sub micron pharmaceutical and electronics rated filter elements. Our relationship with Parker will bring you state of the art cost effective filtration solutions.",

        "Depuis 30 ans, Parker est associé avec Les Projets Industriels pour offrir une gamme complète de cartouches et de boîtiers de filtration pour le territoire de l'Est du Canada. Ils fournissent des filtres à cartouches de 150 microns allant jusqu'aux particules submicroniques de qualité pharmaceutique et électronique. Notre relation avec Parker vous apportera des solutions de filtration de pointe à moindre coût."

    ],

    "rossBanner": [

        "ROSS products are durable and reliable for multimillion cycle applications even in the most hazardous environments for pneumatic and hydraulics systems and applications. We've been trusted partners since 1958, bringing the unique quality of ROSS built valves to Eastern Canada / Quebec.",

        "Les produits Ross sont très robustes et fiables pour les applications de plusieurs millions de cycles même dans les environnements les plus dangereux pour les systèmes et applications pneumatiques et hydrauliques. Ce sont des modèles de confiance depuis 1958, apportant la qualité unique des vannes construites par Ross à l'Est du Canada et au Québec."

    ],

    "rosedaleBanner": [

        "Rosedale is the premier global manufacturer of filter housings and strainers. As a distributer for Rosedale for the past 40 years, we've brought superior performing filtration solutions to the Eastern Canadian market. Rosedale manufactures housings for flow rates up to 5000 gallons a minute and all of the filter media that is required for a fully operational system.",

        "Rosedale est un fabricant de renommée mondiale de boîtiers et de paniers de filtration. En tant que distributeur de Rosedale depuis 40 ans, nous apportons des solutions de filtration de performance supérieure pour le marché de l’Est du Canada. Rosedale fabrique des boîtiers allant jusqu’à 5000 gallons par minute et tous les médias de filtration nécessaires pour un système pleinement opérationnel."

    ],

    "locationTitle": [

        "Where are we located?", "Où sommes-nous situés?"

    ],

    "locationBody": [

        "We have a 4000 sqft facility in Kirkland, Quebec, located easily at close proximity to Dorval airport, highway 40 and to all major transportation routes.\n\nOur exact address is 16807 Hymus Boulevard, Kirkland, Quebec.",

        "Nous disposons d'une installation de 4000 pieds carrés située à Kirkland, Québec, à proximité de l'aéroport de Dorval sur l'autoroute 40 et facilement accessible de toutes les principales voies de transport."

    ],

    //ABOUT

    "company":["The Company", "La compagnie"],

    "companyContent":[

        "Industrial Projects Ltd was founded in 1958 by George Myers and Norman Mathews. George and Norm were employees at Liquid Air and saw an opportunity to use their expertise to provide pneumatic and filtration equipment to Eastern Canadian Industries. \n\nSince its inception, Industrial Projects  has become a leading supplier of pneumatic and filtration equipment to a large number of companies throughout Quebec and Eastern Canada.  Some of our customers include Rio Tinto, Diageo, Kraft, Bombardier, ArcelorMittal, Quebec Iron Ore just to name a few. \n\n Subsequently, Industrial Projects has also always been foremost in bringing pneumatic and filtration technological advancements to our customers. This commitment to providing our customers with the leading advanced technology on the market ensures that our customers are utilizing the most cutting edge technology available.",

        "Les Projets Industriels Ltée. fut fondée en 1958 par George Myers et Norman Mathews. George et Norm étaient des employés de Liquid Air qui ont vu l’opportunité d'utiliser leur expertise pour fournir de l'équipement pneumatique et de filtration aux industries de l'Est du Canada. \n\nDepuis sa création,  les Projets Industriels Ltée est devenu un fournisseur de premier plan d'équipements pneumatiques et de filtration pour un grand nombre d'entreprises à travers le Québec et l'Est du Canada.  Quelques-unes de ces entreprises comprend Rio Tinto, Diageo, Kraft, Bombardier, ArcelorMittal, Quebec Iron Ore. \n\nTout au long de son existence,  les Projets Industriels Ltée a été un chef de file dans l'apport d’avancements technologiques en matière de pneumatique et de filtration à ses clients. Cet engagement de fournir à nos clients des options à la fine pointe de la technologie garantit qu’ils utilisent les procédés les plus avancés disponibles sur le marché."],

    "business":["The Business", "L'entreprise"],

    "businessContent":[

        "The current team at IP has collectively over 70 years experience. Our corporate mission is to provide our customers with the highest level of technical expertise and to give the best customer service possible.  \n\nWe will make every effort to provide the easiest pathway for our customers to reach their objectives. \n\nIndustrial Projects works with our customers to deliver local stock items and time-sensitive deliveries. Our goal is to make our customers feel that dealing with Industrial Projects is the easiest and most efficient possible choice.",

        "L'équipe actuelle compte collectivement plus de 70 ans d'expérience. La mission de notre entreprise est de fournir à nos clients le plus haut niveau d'expertise technique ainsi qu’un niveau de service à la clientèle le plus efficace possible.  \n\nNous mettons tout en œuvre pour permettre à nos clients d'atteindre facilement les objectifs de processus qu'ils se sont fixés. \n\n Les Projets Industriels Ltée travaille avec ses fournisseurs pour fournir un stock de base local ainsi que pour effectuer des livraisons précises. Notre objectif est de faire en sorte que de collaborer avec notre compagnie soit le choix le plus évident possible."],

    "team":["The Team","L'équipe"],

    "president":["President","Président"],

    "opFin":["Operations and Finances","Opérations et finances"],

    "insiderSale":["Inside Sales","Ventes internes"],

    "sales":["Technical Sales","Ventes techniques"],

    "questions":["Have any questions?","Vous avez des questions?"],

    "contactUs":["Contact us!","Contactez nous!"],



    //INDUSTRIES

    "steelCardTitle": ["Steel", "Acier"],

    "steelCardBody": [

        "We supply pneumatic control instruments for high temperature environments that can be used in systems in all areas and types of steel production.",

        "Nous fournissons des instruments de contrôle pneumatique pour des environnements à haute température qui peuvent être utilisés dans des systèmes dans tous les secteurs et types de productions d'acier."

    ],

    "forestCardTitle": ["Forestry", "Forestrerie"],

    "forestCardBody": [

        "We can provide pneumatic control units for highly contaminated environments for sawmills and all wood processing applications with a very high reliability.",

        "Unités de contrôle pneumatique pour environnements fortement contaminés pour scieries et toutes les applications de transformation du bois avec très grande fiabilité."

    ],

    "waterChemCardTitle": ["Water and Chemical", "Eau et produits chimiques"],

    "waterChemCardBody": [

        "We have extensive knowledge in filtration for purification systems of water and chemicals for a wide range of applications.",

        "Filtration pour la purification de l'eau et de produits chimiques pour une très large gamme d'applications."

    ],

    "foodCardTitle": ["Food and Beverage", "Nourriture et boisson"],

    "foodCardBody": [

        "All encompassing range of FDA and NFS approved filtration products for water, food products and beverages",

        "Très grande gamme de produits de filtration approuvés FDA et NFS pour l'eau ainsi que les produits alimentaires et les boissons"

    ],

    "autoCardTitle": ["Automation", "Automatisation"],

    "autoCardBody": [

        "Redundant safety product for automation applications.",

        "Produits de sécurité redondants pour les applications d'automatisation."

    ],

    "manuCardTitle": ["Manufacturing", "Fabrication"],

    "manuCardBody": [

        "Complete scope for all manufacturing processes and applications involving pneumatics and filters.",

        "Gamme complète pour tous les processus de fabrication et leurs applications."

    ],



    //PRODUCTS

    "prodCatalogueTitle": ["Product Catalogue", "Catalogue des produits"],

    "prodCatalogueBody": [

        "Please see beloew for our product catalogue. For more in depth inquiries feel free to contact us with any questions you might have regarding the range of the products we sell.",

        "Voir ci-dessous notre catalogue de produits. Pour des demandes plus spécifiques n’hésitez pas à nous contacter avec toutes vos questions concernant la variété des produits que nous fournissons."

    ],

    "brand": ["By Brand", "Par marque"],

    "product": ["By Product", "Par Produit"],

    //By Brand

    "parkerTitle1": ["Industrial Process Filtration Divison", "Division de Filtration des Processus Industriels"],

    "parkerBody1": [

        "A global leader in filtration & separation products, the Parker Industrial Process Filtration Division brings together over a hundred years of expertise. Our division is the result of the Parker Hannifin acquisition of CLARCOR’s PECO and Purolator Advanced Filtration businesses merged with Parker’s Industrial Process business.",

        "Leader mondial des produits de filtration et de séparation, la division Parker Industrial Process Filtration rassemble plus de cent ans d'expertise. Cette division est le résultat de l’acquisition par Parker Hannifin des activités de PECO et Purolator Advanced Filtration de CLARCOR fusionnées avec le secteur de processus industriels de Parker."

    ],

    "parkerLink1": [

        "Click here for the Parker Industrial Filtration division products page",

        "Cliquez ici pour la page produits de la division Parker Industrial Filtration"

    ],

    "parkerTitle2": ["Parker Bioscience and Water Filtration Division", "Division des Biosciences et de la Filtration de l'eau de Parker"],

    "parkerBody2": [

        "With lead operations located in Oxnard, California and Birtley, UK, Parker Bioscience and Water Filtration Division have over 50 years of experience providing high quality filtration products and services for applications within the life sciences, food & beverage, industrial processing and microelectronics markets.",

        "Avec les opérations principales situées à Oxnard, en Californie et à Birtley, au Royaume-Uni, la division Parker Bioscience et Filtration de l'eau a plus de 50 ans d'expérience à fournir des produits et des services de filtration de haute qualité pour des applications dans les sciences de la vie, les aliments et les boissons, les processus industriels ainsi que les marchés de microélectronique."

    ],

    "parkerLink2": [

        "Click here for the Parker Bioscience and Water Filtration division products page",

        "Cliquez ici pour la page produits de la division Parker Bioscience et Filtration de l'eau"

    ],

    //not implemented in Product page vv

    "rossHeader": [

        "Headquartered in Troy, Michigan, ROSS Controls is an international manufacturer of pneumatic valves, controls systems, and safety products for the fluid power industry.",

        "Basé à Troy au Michigan, ROSS Controls est un fabricant international de valve pneumatiques, de systèmes de contrôle et de produits de sécurité pour l'industrie des liquides."

    ],

    "rossBody1": [

        "Since being established in 1921, ROSS has always been one of the industry’s strongest leaders in pneumatic valve technology. ROSS’ focus is to continue to be a formidable competitor in key industries where its tailored technology offers customers a distinct value advantage.", 

        "Depuis sa création en 1921, ROSS a toujours été un chef mondial reconnu de l'industrie en matière de technologie de valves pneumatiques. L'objectif de ROSS est de continuer à être un concurrent redoutable dans des secteurs clés où sa technologie sur mesure offre aux clients un avantage de valeur distincte."

    ],

    "rossBody2": [

        "As the distributor of Ross Controls products in Eastern Canada for over 60 years, Industrial Projects will help you, through its sales and technical support team, to determine the valve, safety valve, filter, regulator and/or lubricator designated for your needs.", 

        "En tant que distributeur des produits Ross Controls dans l'Est du Canada depuis plus de 60 ans, Les Projets Industriels vous aidera, par l'entremise de son équipe de vente et de soutien technique, à déterminer la soupape, la soupape de sécurité, le filtre, le régulateur et / ou le graisseur conçus pour vos besoins."

    ],

    "rossLink": [

        "Click here for the ROSS Controls products page", 

        "Cliquez ici pour la page produits de ROSS Controls"

    ],

    "rosedaleHeader": [

        "With more than 50 years of experience in the filtration of liquids to its credit, Rosedale has produced a wide range of quality products that meet your filtration needs.",

        "Avec plus de 50 ans d'expérience dans la filtration des liquides à son actif, Rosedale a produit une large gamme de produits de qualité qui répondent à vos besoins de filtration."

    ],

    "rosedaleBody": [

        "Whether your needs are for pre-filtration of municipal water at low flow rates using bag filters and housings or the final stage of particle filtration using cartridge filters and housings, Rosedale has long established its reputation to provide excellent performance at very competitive prices. The technical sales team of Industrial Projects will work with Rosedale engineers to ensure that your filtration requirements are met with precision and accuracy.", 

        "Que vos besoins concernent la préfiltration de l'eau municipale à faible débit à l'aide de filtres à sacs et de boîtiers ou une dernière étape de la filtration de particules à l'aide de filtres à cartouches et boîtiers, Rosedale a depuis longtemps établi sa réputation en offrant d'excellentes performances à des prix très compétitifs. L'équipe de vente technique des Projets Industriels travaillera avec les ingénieurs de Rosedale pour s'assurer que vos exigences de filtration soient satisfaites avec précision et exactitude."

    ],

    "rosedaleLink": [

        "Click here for Rosedale product page", 

        "Cliquez ici pour la page produit Rosedale"

    ],

    "clarkHeader": [

        "Clark-Reliance is dedicated to supplying the largest and broadest product line in the instrumentation industry for all types of measurement and control.",

        "Clark-Reliance se consacre à fournir une gamme de produits la plus vaste et la plus variée de l'industrie de l'instrumentation pour tous les types de mesure et de contrôle."

    ],

    "clarkBody": [

        "Key acquisitions over the last few decades have solidified Clark-Reliance as a leader in the separation and filtration industries as well. Clark-Reliance now includes a number of distinguished product lines: Jerguson®, Jacoby-Tarbox®, Magne-Sonics®, Anderson® Separator and National Filtration Systems®.", 

        "Des acquisitions clés au cours des dernières décennies ont également consolidé Clark-Reliance en tant que chef dans les secteurs de la séparation et de la filtration. Clark-Reliance comprend désormais des lignes hautes gammes de produits distingués tels que Jerguson®, Jacoby- Tarbox®, Magne-Sonics®, Anderson® Separator et National Filtration Systems®."

    ],

    "clarkLink": [

        "Click here for the Clark Reliance products page", 

        "Cliquez ici pour la page produits Clark Reliance"

    ],

    "conantHeader": [

        "Conant designs and manufactures a full line of the highest quality selector valves, stackable valves and precision control vent valves to ensure trouble-free, long-lasting operation.",

        "Conant conçoit et fabrique une gamme complète de vannes de sélection de haute qualité, de vannes empilables et de vannes de mise à l'air de contrôle de précision pour assurer un fonctionnement sans problème et de longue durée."

    ],

    "conantBody": [

        "These self-lubricating valves exhibit high resistance to most chemicals, gases, and solvents. They can withstand temperatures between -51.11 and 204.44°C (-60 and 400 °F). Before dispatch, each valve, tap or flap undergoes air pressure tests underwater so that the reliability of the product is guaranteed.", 

        "Ces soupapes autolubrifiantes présentent une résistance élevée à la plupart des produits chimiques, des gaz et des solvants. Elles peuvent supporter des températures entre -51.11 et 204.44 ° C (-60 et 400 ° F). Avant expédition, chaque vanne, robinet et/ou volet subit des tests de pression d'air sous l'eau afin que la fiabilité du produit soit garantie."

    ],

    "conantLink": [

        "Click here for the Conant Controls products page", 

        "Cliquez ici pour la page produits de Conant Controls"

    ],

    "jfpiHeader": [

        "JFPI is one of the largest manufacturers of depth wound filters in the world. JFPI is a worldwide manufacturer of these products with distributors in the U.S. and (24) overseas countries.",

        "JFPI est l'un des plus grands fabricants de filtres à enroulement en profondeur au monde. JFPI est un fabricant mondial de ces produits avec des distributeurs aux États-Unis et dans (24) pays d'outre-mer."

    ],

    "jfpiBody1": [

        "Their filters are used in liquid filtration applications from drinking water, photographic, beverage processing, plating industry, plating, textile and many others.", 

        "Leurs filtres sont utilisés dans les applications de filtration des liquides tels que pour l'eau potable, la photographie, le traitement /production des boissons, l'industrie du placage et du textile et bien d'autres."

    ],

    "jfpiBody2": [

        "Precision depth wound filter cartridges have been referred to as a mature market, but time has proven the value of this product. With new advances in materials, media, processing, and testing; this product will continue to be a very useful and economical product.", 

        "Les cartouches filtrantes à enroulement de précision ont été décrites comme etant un marché mature, par contre le temps et l’expérience ont prouvé la valeur de ce produit. À l’aide de nouvelles découvertes dans le secteur des matériaux, les médias, les processus et des tests, ce produit demeurera un produit très utile et économique."

    ],

    "jfpiLink": [

        "Click here for the JFPI products page", 

        "Cliquez ici pour la page produits de JFPI"

    ],

    "sacHeader": [

        "Le Sac is a manufacturers of high quality micron rated liquid filter bags, Nomex bags, oil absorbent bags, specialty bags and dust collection bags.",

        "Le Sac est un fabricant de sacs filtrants de haute qualité classés micron pour liquides, sacs Nomex, sacs absorbant d'huile, sacs spécialisés et sacs de collecte de poussière."

    ],

    "sacBody": [

        "They also supply high quality bag filter housings of varying sizes, pressure ratings, and materials. The technical sales team of Industrial Projects will work in conjunction with the Le Sac team to meet your liquid/dust filtration, and housing needs.", 

        "Ils fournissent également des boîtiers de filtration en sac à manches de haute qualité de différentes tailles, pressions nominales et matériaux. L'équipe technique de vente des Projets Industriels travaillera en collaboration avec les conseillers chez Le Sac pour répondre à vos besoins de filtration de liquides / poussières ainsi que les boîtiers nécessaires pour vos processus."

    ],

    "sacLink": [

        "Click here for the LeSac products page", 

        "Cliquez ici pour la page produits de LeSac"

    ],

    //By Product

    "pneumaticsTitle": ["Pneumatic Valves","Valves pneumatiques"],

    "pneumaticsBody": [

        "We have an entire set of pneumatic products and accessories from the following brands:",

        "Nous disposons d'une gamme complète de produits pneumatiques et d'accessoires des marques suivantes:"

    ],

    "pneu1": ["ROSS Safety Valves","Valves de sécurité ROSS"],

    "pneu2": ["ROSS Lockout Valves","Valves de verrouillage ROSS"],

    "pneu3": ["ROSS Press Valves","Valves de presse ROSS"],

    "pneu4": ["Conant Pneumatic Valves","Valves pneumatiques Conant"],

    "hydraulicsTitle": ["Hydraulic Safety Valves","Valves de sécurité hydrauliques"],

    "hydraulicsBody": [

        "We have specialized hydraulic safety valves designed for block-hold an block-bleed functions:",

        "Nous disposons des valves de sécurité hydrauliques spécialisées, conçues pour les fonctions de blocage et de purge:"

    ],

    "hydrau1": ["ROSS Safety Valves","Valves de sécurité ROSS"],

    "cartridgeTitle": ["Filter Cartridges","Cartouches filtrantes"],

    "cartridgeBody": [

        "Parker offers a broad range of filter elements designed for various industrial and commercial purposes:",

        "Parker propose une large gamme d'éléments filtrants conçus pour divers usages industriels et commerciaux:"

    ],

    "cart1": ["Industrial Process Filtration Divison", "Division de Filtration des Processus Industriels"],

    "cart2": ["Parker Bioscience and Water Filtration Division", "Division des Biosciences et de la Filtration de l'eau de Parker"],

    "housingsTitle": ["Filter Housings and Acccessories", "Boîtiers de filtres et accessoires"],

    "housingsBody": [

        "We have a variety of filter housings availible from Parker and Rosedale to suit any application:",

        "Nous disposons d'une grande variété de boîtiers de filtres Parker et Rosedale pour répondre à toutes les applications:"

    ],

    "housingsRosedale": ["Rosedale Housings and Accessories", "Boîtiers et accessoires Rosedale"],

    "housingsParker": ["Parker Housings and Accessories", "Boîtiers et accessoires Parker"],

    "bagsTitle": ["Filter Bags","Sacs filtrants"],

    "bagsBody": [

        "We have all a complete selection of filter bags to use in any filtration system:",

        "Nous disposons d'une sélection complète de sacs filtrants pour utilisation avec n'importe quel système de filtration:"

    ],

    "bagsRosedale": ["Rosedale Filter Bags", "Sacs filtrants Rosedale"],

    "bagsLeSac": ["LeSac Filter Bags", "Sacs filtrants LeSac"],

    

    

    //CONTACT

    "contactUsTitle":["Contact Us","Contactez-nous"],

    "contactUsBody": [

        "Please fill out the form below so that we can help you with any questions or inquiries you may have. If possible, please include the part number of a product that you are interested in.",

        "Veuillez SVP remplir le formulaire ci-dessous afin que nous puissions répondre à vos questions. Si possible, veuillez indiquer la référence du produit qui vous intéresse."

    ],

    "ourContactTitle": ["Our Contact Information", "Nos coordonnées"],

    "ourContactAddress": [

        "    Address: 16807 Hymus Blvd, Kirkland, QC H9H 3L4",

        "    Adresse : 16807, boul. Hymus, Kirkland, QC H9H 3L4"

    ],

    "ourContactTelephone": [

        "    Telephone: +1 (514) 630-0200",

        "    Téléphone: +1 (514) 630-0200"

    ],

    "ourContactEmail": [

        "    Email: service@industrialprojects.ca",

        "    Courriel: service@industrialprojects.ca"

    ],

    "formTitle": [

        "Please fill out all of the following fields:",

        "Veuillez remplir tous les éléments suivants:"

    ],

    "formName": [

        "Full name:",

        "Nom et prénom:"

    ],

    "formEmail": [

        "Email:",

        "Courriel:"

    ],

    "formPhone": [

        "Phone Number:",

        "Numéro de téléphone"

    ],

    "formBusiness": [

        "Company / Business:",

        "Société / Entreprise:"

    ],

    "formMessage": [

        "Message:",

        "Message:"

    ],

    "formSubmit": [

        "Submit",

        "Soumettre"

    ],

    "modalMessage": [

        "An email was sent to our representatives, we will be in touch with you shortly. \n\nPlease click anywhere to continue",

        "Un courriel a été envoyé à nos représentants, nous vous contacterons dans les plus brefs délais. \n\nVeuillez cliquer n'importe où pour continuer."

    ],

};

export default writtenContent