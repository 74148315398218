
import { LuCopyright } from 'react-icons/lu'
import useLanguage from "../hooks/use-language";
import Container from "./Container";



function FooterBar() {

    const { translate } = useLanguage()

    return (

        <Container className="absolute bottom-0 z-100 min-w-[100vw]">
            <div className="grow"/>
            <div className='bg-gray-50 flex flex-row align-items-center min-h-[50px] pl-10 pr-10'>
            <LuCopyright></LuCopyright>
            <div className='ml-4'>
                {translate('footer')}
            </div>
        </div>
        </Container>
        
    );
}
export default FooterBar;