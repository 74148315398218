import { useState } from 'react';
import { GoChevronDown, GoChevronLeft } from 'react-icons/go';

import classNames from "classnames";


function ExpandablePanel({ header, children, headerLogo, className, transition }) {
    const [expanded, setExpanded] = useState(false);

    const panelClasses = classNames(
        ' ml-4 mr-4 mb-2 mt-2 cursor-pointer border-2 border-black',
        className,
        transition && 'transition ease-in-out delay-25 hover:-translate-y-1 hover:shadow-lg duration-300'
    );

    const handleClick = () => {
        setExpanded(!expanded);
    }
    return (
        <div className={panelClasses} onClick={handleClick}>
            <div className='flex p-2 justify-between items-center'>
                <div className="flex flex-row items-center justify-between text-2xl">
                    {header}
                    <img src={headerLogo} alt='logo' height='200' className='rounded-md max-h-[100px]' />
                </div>
                <div>
                    {expanded ? <GoChevronDown /> : <GoChevronLeft />}
                </div>
            </div>
            {expanded && <div className="p-5 border-t border-black">{children}</div>}
        </div>
    );
}
export default ExpandablePanel;