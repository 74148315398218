import Container from '../components/Container';
import IndustryCard from '../components/IndustryCard';
import steel from '../content/steel.jpg'
import forestry from '../content/forestry.jpg'
import water from '../content/water.jpg'
import food from '../content/food.jpg'
import automation from '../content/automation.jpg'
import manufacturing from '../content/manufacturing.jpg'

import useLanguage from "../hooks/use-language";




function Industries() {

    // FOOD AND BEVARAGE
    // AUTOMATION
    // Manufacturing / processing

    const { translate } = useLanguage()


    const industryData = {
        steel : {
            title : translate('steelCardTitle'),
            desc : translate('steelCardBody'), 
        },
        forest : {
            title : translate('forestCardTitle'),
            desc : translate('forestCardBody')        
        },
        water : {
            title : translate('waterChemCardTitle'),
            desc : translate('waterChemCardBody') 
        },
        food : {
            title : translate('foodCardTitle'),
            desc : translate('foodCardBody') 
        },
        automation : {
            title : translate('autoCardTitle'),
            desc : translate('autoCardBody') 
        },
        manufacturing : {
            title : translate('manuCardTitle'),
            desc : translate('manuCardBody') 
        },
    }
    // flex flex-wrap wrap m-5 bg-white justify-between
    return (
        <Container className='flex flex-wrap m-5 bg-white justify-around'>
            {/* Unplash license - lost link*/}
            <IndustryCard imgSrc={steel} title={industryData.steel.title} desc={industryData.steel.desc}/>
            {/* Evgeni Evgeniev - unsplash license - https://unsplash.com/photos/scenery-of-forest-trees-LPKk3wtkC-g*/}
            <IndustryCard imgSrc={forestry} title={industryData.forest.title} desc={industryData.forest.desc}/>
            {/* Akira Hojo - unsplash license - https://unsplash.com/photos/body-of-water-ZxGdri2EWzk*/}
            <IndustryCard imgSrc={water} title={industryData.water.title} desc={industryData.water.desc}/>
            {/* Adele Payman - unsplash license - https://unsplash.com/photos/green-leafed-plants-during-daytime-2oYMwuFgnTg */}
            <IndustryCard imgSrc={food} title={industryData.food.title} desc={industryData.food.desc}/>
            {/* Arno Senoner - unsplash license - https://unsplash.com/photos/a-model-of-a-ship-PXZXr7EASLc */}
            <IndustryCard imgSrc={automation} title={industryData.automation.title} desc={industryData.automation.desc}/>
            {/* Max - unsplash license - https://unsplash.com/photos/mechanical-engine-N2_oYBGScDQ */}
            <IndustryCard imgSrc={manufacturing} title={industryData.manufacturing.title} desc={industryData.manufacturing.desc}/>

        </Container>
    );
}
export default Industries;