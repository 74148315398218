import Link from "./Link";
import classNames from "classnames";
import logo from '../content/ip_logo_bl.jpg'
import { VscThreeBars } from 'react-icons/vsc';
import { useState } from "react";
import useLanguage from "../hooks/use-language";



function Navbar() {

    const { setLanguage, translate } = useLanguage()

    const [menu, setMenu] = useState(false);

    //here we can use this object to create an array, the create a link for each object
    const links = [
        { label: translate('navHome'), path: '/' },
        { label: translate('navAbout'), path: '/about' },
        { label: translate('navIndustries'), path: '/industries' },
        { label: translate('navProducts'), path: '/products' },
        { label: translate('navContact'), path: '/contact' },

    ];

    const navBarClasses = classNames(
        'min-h-[10vh] flex flex-row items-center justify-between sticky top-0 pl-[2rem] pr-[2rem] bg-white z-50 drop-shadow',

    );

    //Classname constants
    const navItemsClasses = classNames(
        'm-3 no-underline text-[2vh]',
        //transition classes:
        'transition ease-in-out delay-25 hover:-translate-y-1 hover:scale-110 duration-300',
    );

    const activeNavItemsClasses = classNames(
        'font-bold border-b-2 border-blue-500'
    );

    const renderedLinks = links.map((link) => {
        return (
            <Link
                key={link.label}
                to={link.path}
                className={navItemsClasses}
                activeClassName={activeNavItemsClasses}>
                {link.label}
            </Link>
        );
    })

    //Classname constants
    const navMiniItemsClasses = classNames(
        'm-3 no-underline',
        //transition classes:
        'transition ease-in-out delay-25 hover:-translate-y-1 duration-300',
    );

    const langButtonClasses = classNames(
        'm-3 no-underline',
        //transition classes:
        'transition ease-in-out delay-25 hover:-translate-y-1 duration-300 self-start',
    );

    const activeNavMiniItemsClasses = classNames(
        'font-bold'
    );

    const smallLinks = links.map((link) => {
        return (
            <Link
                key={link.label}
                to={link.path}
                className={navMiniItemsClasses}
                activeClassName={activeNavMiniItemsClasses}>
                {link.label}
            </Link>
        );
    })

    const handleMenuClick = () => {
        setMenu(!menu)
    }

    const menuLinks = classNames(
        'xl:hidden',
        !menu && 'hidden',
        'flex flex-column sticky top-0 bg-white shadow-md'

    )

    const handleLangClick = () => {
        setLanguage()
    }

    return (
        <div className="sticky top-0 z-50">
            <div className={navBarClasses}>
                <img src={logo} alt='logo' className='max-w-[50vw]' />
                <div className='hidden xl:flex'>{renderedLinks}
                    <button className={navItemsClasses} onClick={handleLangClick}>
                        {translate('lang')}
                    </button>
                </div>
                <VscThreeBars className='xl:hidden mr-[1vh] text-5xl' onClick={handleMenuClick} />
            </div>
            <div className={menuLinks} onClick={()=>handleMenuClick()}>
                {menu && smallLinks}
                <button className={langButtonClasses} onClick={handleLangClick}>
                    {translate('lang')}
                </button>
            </div>
        </div>

    );
}
export default Navbar;