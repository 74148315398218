import React from 'react'
import classNames from 'classnames';


function Map({ zoomLevel, className }) {

  const mapClasses = classNames(
    className,
  );

  return (
    <div className={mapClasses}>
       <iframe 
    title='map'
    width="100%" 
    height="100%" 
    style={{ border: 0 }}
    loading="lazy" 
    allowFullScreen
    src="https://www.google.com/maps/embed/v1/search?q=Industrial+Projects+Ltd,+Hymus+Boulevard,+Kirkland,+QC,+Canada&key=AIzaSyBFt9FMoqfEqBU2VD-ujHw7r0zTSkmLPxc">

    </iframe>
    </div>
   
    )

}
export default Map