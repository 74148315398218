import '@coreui/coreui/dist/css/coreui.min.css'

import Navbar from './components/Navbar';
import FooterBar from './components/FooterBar';
import Route from './components/Route';
import Home from './pages/Home';
import About from './pages/About';
import Industries from './pages/Industries';
import Products from './pages/Products';
import Contact from './pages/Contact';




function App() {


    return (

        <div className="page-container">
            <Navbar/>
            <Route path="/">
                <Home />
            </Route>
            <Route path="/about">
                <About />
            </Route>
            <Route path="/industries">
                <Industries/>
            </Route>
            <Route path="/products">
                <Products/>
            </Route>
            <Route path="/contact">
                <Contact/>
            </Route>
            <div className='min-h-[50px] invisible -z-25'/>
            <FooterBar/>

            
        </div>);

}
export default App;