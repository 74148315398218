import { useState, createContext } from "react";
import writtenContent from "../content/writtenContent.js";

const LanguageContext = createContext();

function LanguageProvider({ children }) {

    const [currentLanguage, setLang] = useState(true)

    const setLanguage = () => {
        setLang(!currentLanguage);
        console.log('Changing Language')
    };

    const translate = (section) => {

        console.log(writtenContent)
        if (section === null) return 'blank'
        if (currentLanguage)
            return writtenContent[section][0]
        else
            return writtenContent[section][1]
    }

    return <LanguageContext.Provider value={{ currentLanguage, setLanguage, translate }}>
        {children}
    </LanguageContext.Provider>

}

export { LanguageProvider };
export default LanguageContext;