import { useState } from 'react';

import Container from '../components/Container';

//Brand Pictures
import parkerLogo from '../content/parker1.png'
import rossLogo from '../content/ross1.jpg'
import rosedaleLogo from '../content/rose1.png'
import clark1 from '../content/clark1.png'
import conant1 from '../content/conant1.jpg'
import john1 from '../content/johnsonlogo.png'
// import leSac from '../content/leSac.png'

//Products Pictures
import dm2Pic from '../content/dm2_pic.png'
import hbb from '../content/HBB_Hydraulic_Valve.png'
import bags from '../content/filterbags.jpg'
import cartriges from '../content/cartridges.jpeg'
import housings from '../content/rosedaleHousings.jpg'






import ExpandablePanel from "../components/ExpandablePanel";
import Button from "../components/Button";
import classNames from 'classnames';
import useLanguage from "../hooks/use-language";



function Products() {


    const [productCatalogue, setCatalogue] = useState(false);

    const { translate } = useLanguage()


    let products

    if (!productCatalogue) {

        products =

            <div>
                <ExpandablePanel headerLogo={parkerLogo} className='bg-amber-400' transition>
                    <h4>{translate('parkerTitle1')}</h4>
                    <p>{translate('parkerBody1')}</p>
                    <a href='https://www.parker.com/portal/site/PARKER/menuitem.223a4a3cce02eb6315731910237ad1ca/?vgnextoid=2937fed9d970f510VgnVCM100000e6651dacRCRD&vgnextfmt=EN'>{translate('parkerLink1')}</a>
                    <h4 className='mt-4'>{translate('parkerTitle2')}</h4>
                    <p>{translate('parkerBody2')}</p>
                    <a href='https://www.parker.com/portal/site/PARKER/menuitem.223a4a3cce02eb6315731910237ad1ca/?vgnextoid=879d000b2875e210VgnVCM10000048021dacRCRD&vgnextfmt=EN'>{translate('parkerLink2')}</a>
                </ExpandablePanel>
                <ExpandablePanel headerLogo={rossLogo} transition>
                    <p className='font-bold'>{translate('rossHeader')}</p>
                    <p>{translate('rossBody1')}</p>
                    <p>{translate('rossBody2')}</p>
                    <a href='https://www.rosscanada.com/'>{translate('rossLink')}</a>

                </ExpandablePanel>
                <ExpandablePanel headerLogo={rosedaleLogo} className='bg-roseGray text-white' transition>
                    <p className='font-bold'>{translate('rosedaleHeader')}</p>
                    <p>{translate('rosedaleBody')}</p>
                    <a href='https://www.rosedaleproducts.com/'>{translate('rosedaleLink')}</a>
                </ExpandablePanel>
                <ExpandablePanel headerLogo={clark1} transition>
                    <p className='font-bold'>{translate('clarkHeader')}</p>
                    <p>{translate('clarkBody')}</p>
                    <a href='https://clarkreliance.com/products/'>{translate('clarkLink')}</a>
                </ExpandablePanel>
                <ExpandablePanel headerLogo={conant1} transition>
                    <p className='font-bold'>{translate('conantHeader')}</p>
                    <p>{translate('conantBody')}</p>
                    <a href='https://www.conantcontrols.com/'>{translate('conantLink')}</a>
                </ExpandablePanel>
                <ExpandablePanel headerLogo={john1} transition>
                    <p className='font-bold'>{translate('jfpiHeader')}</p>
                    <p>{translate('jfpiBody1')}</p>
                    <p>{translate('jfpiBody2')}</p>
                    <a href='https://www.johnsonfiltration.com/'>{translate('jfpiLink')}</a>
                </ExpandablePanel>
                {/* <ExpandablePanel headerLogo={leSac} transition>
                    <p className='font-bold'>{translate('sacHeader')}</p>
                    <p>{translate('sacBody')}</p>
                    <a href='http://www.lesac-filters.com/'>{translate('sacLink')}</a>
                </ExpandablePanel> */}
                
            </div>

    }

    else {
        //add clark reliance somewhre..?

        products =

            <div>
                <ExpandablePanel transition header={<div className='mr-4'>{translate('pneumaticsTitle')}</div>} headerLogo={dm2Pic} className='flex flex-col'>
                    <h4>{translate('pneumaticsBody')}</h4>
                    <div className='flex flex-col'>
                        <a href='https://www.rosscanada.com/' className='no-underline text-3xl m-2'>{translate('pneu1')}</a>
                        <a href='https://www.rosscanada.com/' className='no-underline text-3xl m-2'>{translate('pneu2')}</a>
                        <a href='https://www.rosscanada.com/' className='no-underline text-3xl m-2'>{translate('pneu3')}</a>
                        <a href='https://www.conantcontrols.com/' className='no-underline text-3xl m-2'>{translate('pneu4')}</a>
                    </div>


                </ExpandablePanel>
                <ExpandablePanel transition header={<div className='mr-4'>{translate('hydraulicsTitle')}</div>} headerLogo={hbb}>
                    <h4>{translate('hydraulicsBody')}</h4>
                    <div className="flex flex-col">
                        <a href='https://www.conantcontrols.com/' className='no-underline text-3xl m-2'>{translate('hydrau1')}</a>
                    </div>

                </ExpandablePanel>
                <ExpandablePanel transition header={<div className='mr-4'>{translate('cartridgeTitle')}</div>} headerLogo={cartriges}>
                    <h4>{translate('cartridgeBody')}</h4>
                    <div className="flex flex-col">
                        <a href='https://www.parker.com/portal/site/PARKER/menuitem.223a4a3cce02eb6315731910237ad1ca/?vgnextoid=879d000b2875e210VgnVCM10000048021dacRCRD&vgnextfmt=EN' className='no-underline text-3xl m-2'>{translate('cart2')}</a>
                        <a href='https://www.parker.com/portal/site/PARKER/menuitem.223a4a3cce02eb6315731910237ad1ca/?vgnextoid=2937fed9d970f510VgnVCM100000e6651dacRCRD&vgnextfmt=EN' className='no-underline text-3xl m-2'>{translate('cart1')}</a>
                    </div>

                </ExpandablePanel>
                <ExpandablePanel transition header={<div className='mr-4'>{translate('housingsTitle')}</div>} headerLogo={housings}>
                    <h4>{translate('housingsBody')}</h4>
                    <div className="flex flex-col">
                        <a href='https://www.rosedaleproducts.com/' className='no-underline text-3xl m-2'>{translate('housingsRosedale')}</a>
                        <a href='https://ph.parker.com/us/en/series/liquid-filter-vessels' className='no-underline text-3xl m-2'>{translate('housingsParker')}</a>

                    </div>
                </ExpandablePanel>
                <ExpandablePanel transition header={<div className='mr-4'>{translate('bagsTitle')}</div>} headerLogo={bags}>
                    <h4>{translate('bagsBody')}</h4>
                    <div className="flex flex-col">
                        <a href='https://www.rosedaleproducts.com/' className='no-underline text-3xl m-2'>{translate('bagsRosedale')}</a>
                        {/* <a href='http://www.lesac-filters.com/' className='no-underline text-3xl m-2'>{translate('bagsLeSac')}</a> */}
                    </div>

                </ExpandablePanel>

            </div>
    }

    const handleClickProduct = () => {
        setCatalogue(true);
    }

    const handleClickBrand = () => {
        setCatalogue(false);
    }

    const buttonBrandClasses = classNames(
        'transition ease-in-out delay-50 hover:-translate-y-1',
        'hover:scale-110 hover:bg-indigo-500 duration-300',
        'max-w-[150px] max-h-[50px] rounded-full p-2 font-bold border-2 border-blue-500',
        !productCatalogue && 'bg-blue-500 text-white'

    );

    const buttonProductClasses = classNames(
        'transition ease-in-out delay-50 hover:-translate-y-1',
        'hover:scale-110 hover:bg-indigo-500 duration-300',
        'max-w-[150px] max-h-[50px] rounded-full p-2 font-bold border-2 border-blue-500',
        productCatalogue && 'bg-blue-500 text-white'
    )

    return (
        <div>
            <Container className='flex flex-col items-center min-h-[5vh] max-h-[200px]'>
                <h1 className='m-4 text-center'>{translate('prodCatalogueTitle')}</h1>
                <h4 className='ml-5 mr-5 text-center'>{translate('prodCatalogueBody')}</h4>
            </Container>
            <div className='flex flex-row justify-around mt-5 mb-5'>


                <Button
                    onClick={handleClickBrand}
                    className={buttonBrandClasses}
                >
                    {translate('brand')}
                </Button>
                <Button
                    onClick={handleClickProduct}
                    className={buttonProductClasses}
                >
                    {translate('product')}
                </Button>
            </div>
            {products}

        </div>
    );
}
export default Products;