import classNames from "classnames";

function IndustryCard({ imgSrc, title, desc, className }) {

    const cardClassname = classNames(
        "relative ml-5 mr-5 mb-5 max-w-[50vh]",
        //"transition ease-in-out delay-25 hover:-translate-y-1 hover:scale-105 duration-300",
        className
    );

    return (
        <div className={cardClassname}>
            <img className='max-h-[700px] w-[50vh] h-[700px] rounded-md' src={imgSrc} alt='industry' />
            <div className="absolute inset-x-0 bottom-0 bg-white opacity-80">
                <h1 className="m-2 opacity-100">{title}</h1>
                <p className="m-2 max-w-[90%]">{desc}</p>
            </div>

        </div>
    );
}
export default IndustryCard;