//Non native imports

//TODO: CHANGE ICONS< WORK ON BANNER FONTS, WORK ON WHERE ARE WE SECTION
import {
    CCol,
    CRow,
    CCarousel,
    CCarouselItem,
    CImage,
    CCarouselCaption,
    CCard,
    CCardBody,
    CCardImage,
    CCardText,
    CCardTitle,
    CCardFooter,


} from '@coreui/react';

import { IconContext } from "react-icons";
import { TbAward } from 'react-icons/tb'
import { CgPerformance } from 'react-icons/cg'
import { RiQuestionAnswerLine } from 'react-icons/ri'


//Native imports
import factoryImg1 from '../content/carousel/factoryImg1.jpg';
import automation1 from '../content/carousel/automation1.jpg';
import agriculture1 from '../content/carousel/agriculture1.jpg';
import press1 from '../content/carousel/press1.jpg';
import parkerLogo from '../content/parkerInverted.png'
import parkerCartridge from '../content/parkerCartriges.avif'

//Cards
import hbb from '../content/HBB_Hydraulic_Valve.png'
import rosedaleHousingCollection from './../content/rosedaleHousingCollection.jpg'
import parkerCollection from './../content/cartridges.jpeg'

import rossLogo from '../content/rossLogo.jpg'
import dm2Pic from '../content/dm2_pic.png'

import rosedaleLogo from '../content/rosedaleLogoInverted.png'
import rosedaleHousing from '../content/rosedaleHousings.png'

import Container from "../components/Container";
import Map from '../components/Map';

import useLanguage from "../hooks/use-language";



function Home() {

    const { translate } = useLanguage()


    return (
        <div className="max-w-[100vw]">
            <CCarousel className="max-h-[85vh] min-w-[100vw]" transition='crossfade' interval={10000} pause={false} indicators controls>
                <CCarouselItem>
                    <CCarouselCaption className="d-none d-md-block -translate-y-[5vh] carouselBg">
                        <h1 className='text-white text-[4vw]'>{translate('ip')}</h1>
                        <p className='text-white text-[2vw]'>{translate('carousel1')}</p>
                    </CCarouselCaption>
                    {/* Lalit Kumar - unsplash license -  https://unsplash.com/photos/white-and-red-train-in-a-train-station-HpPmiduLDC0*/}
                    <CImage className="max-h-[85vh] min-w-[100vw] d-block object-fill" src={factoryImg1} alt="slide 1" />
                </CCarouselItem>
                <CCarouselItem>
                    <CCarouselCaption className="d-none d-md-block -translate-y-52 carouselBg">
                        <h1 className='text-[4vw]'>{translate('carousel2')}</h1>
                    </CCarouselCaption>
                    {/* Zura Narimanishvili - unsplash license - https://unsplash.com/photos/brown-wheat-field-during-daytime-ovzLiBLMi0I */}
                    <CImage className="max-h-[85vh] min-w-[100vw] d-block object-fill" src={agriculture1} alt="slide 2" />
                </CCarouselItem>
                <CCarouselItem>
                    <CCarouselCaption className="d-none d-md-block -translate-y-52 carouselBg">
                        <h1 className='text-[4vw]'>{translate('carousel3')}</h1>
                    </CCarouselCaption>
                    {/* Lenny Kuhne - unsplash license - https://unsplash.com/photos/gray-vehicle-being-fixed-inside-factory-using-robot-machines-jHZ70nRk7Ns */}
                    <CImage className="max-h-[85vh] min-w-[100vw] d-block object-fill" src={automation1} alt="slide 3" />
                </CCarouselItem>
                <CCarouselItem>
                    <CCarouselCaption className="d-none d-md-block -translate-y-52 carouselBg">
                        <h1 className='text-[4vw]'>{translate('carousel4')}</h1>
                    </CCarouselCaption>
                    {/* Christopher Burns - unsplash license - https://unsplash.com/photos/person-holding-tool-during-daytime-8KfCR12oeUM */}
                    <CImage className="max-h-[85vh] min-w-[100vw] d-block object-fill" src={press1} alt="slide 4" />
                </CCarouselItem>
            </CCarousel>
            <Container className='flex flex-col items-center'>
                <h1 className='m-5'>{translate('whoAreWe')}</h1>
                <p className='m-5 mt-2 text-2xl whitespace-pre-line'>{translate('homeFounded')}</p>
            </Container>
            <Container className='flex flex-row m-5 bg-white'>
                <CRow xs={{ cols: 1 }} md={{ cols: 3 }} className="g-4 xs:w-full">
                    <CCol xs>
                        <CCard className="h-100">
                            <CCardImage orientation="top" src={hbb} />
                            <CCardBody>
                                <CCardTitle className="text-[2vh]">{translate('homeCard1')}</CCardTitle>
                                <CCardText>
                                    {translate('homeCard1Body')}
                                </CCardText>
                            </CCardBody>
                            <CCardFooter>
                                <small className="text-medium-emphasis">25 / 10 / 2023</small>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                    <CCol xs>
                        <CCard className="h-100">
                            <CCardImage orientation="top" src={rosedaleHousingCollection} />
                            <CCardBody>
                                <CCardTitle className="text-[2vh]">{translate('homeCard2')}</CCardTitle>
                                <CCardText>
                                    {translate('homeCard2Body')}
                                </CCardText>
                            </CCardBody>
                            <CCardFooter>
                                <small className="text-medium-emphasis">25 / 10 / 2023</small>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                    <CCol xs>
                        <CCard className="h-100">
                            <CCardImage orientation="top" src={parkerCollection} className='p-2' />
                            <CCardBody>
                                <CCardTitle className="text-[2vh]">{translate('homeCard3')}</CCardTitle>
                                <CCardText>
                                    {translate('homeCard3Body')}
                                </CCardText>
                            </CCardBody>
                            <CCardFooter>
                                <small className="text-medium-emphasis">25 / 10 / 2023</small>
                            </CCardFooter>
                        </CCard>
                    </CCol>
                </CRow>
            </Container>
            <Container className='gradient-bg content-end min-h-[40vh] flex lg:h-[30vh]'>
                <IconContext.Provider value={{ color: "white", className: "", size: '10em', }}>
                    <div className='grow h-full flex flex-col lg:flex-row justify-evenly lg:items-center'>
                        <div className='flex flex-col items-center min-w-[230px]'>
                            <TbAward />
                            <h1 className='text-center'>{translate('icon1')}</h1>
                        </div>
                        <div className='flex flex-col items-center min-w-[230px]'>
                            <CgPerformance />
                            <h1 className='text-center'>{translate('icon2')}</h1>
                        </div>
                        <div className='flex flex-col items-center min-w-[230px]'>
                            <RiQuestionAnswerLine />
                            <h1 className='text-center'>{translate('icon3')}</h1>
                        </div>
                    </div>
                </IconContext.Provider>
            </Container>
            <Container className='bg-amber-400 pt-2 pb-2'>
                <div className='flex flex-col lg:flex-row w-full items-center'>
                    <div className='flex flex-col ml-[5vw] mr-[5vw] lg:m-0 grow lg:w-[400px] lg:h-[400px] pt-[10vh] pb-[10vh] lg:p-2 place-content-center'>
                        <h1 className='text-center'>PARKER HANNIFIN</h1>
                        <p className='text-center lg:text-[1.2vw] lg:max-w-[30vw] lg:text-center self-center'>{translate('parkerBanner')}</p>
                    </div>
                    <img className='max-w-[400px] max-h-[400px]' src={parkerCartridge} alt='parker filters' />
                    <img className='max-w-[400px] max-h-[400px]' src={parkerLogo} alt='parker logo' />
                </div>
            </Container>
            <Container className='pt-2 pb-2'>
                <div className='flex flex-col lg:flex-row w-full items-center'>
                    <img className='max-w-[400px] max-h-[400px] m-2' src={rossLogo} alt='parker logo' />
                    <div className='flex flex-col ml-[5vw] mr-[5vw] lg:m-0 grow lg:w-[400px] lg:h-[400px] pt-[10vh] pb-[10vh] lg:p-2 place-content-center'>
                        <h1 className='text-center'>ROSS CANADA</h1>
                        <p className='text-center lg:text-[1.2vw] lg:max-w-[30vw] lg:text-center self-center'>{translate('rossBanner')}</p>
                    </div>
                    <img className='max-w-[400px] max-h-[400px]' src={dm2Pic} alt='parker filters' />

                </div>
            </Container>
            <Container className='bg-rosedaleGray pt-2'>
                <div className='flex flex-col lg:flex-row w-full items-center'>
                    <div className='flex flex-col ml-[5vw] mr-[5vw] lg:m-0 grow lg:w-[400px] lg:h-[400px] pt-[10vh] pb-[10vh] lg:p-2 place-content-center text-white'>
                        <h1 className='text-center'>ROSEDALE SOLUTIONS</h1>
                        <p className='text-center lg:text-[1.2vw] lg:max-w-[30vw] lg:text-center self-center'>{translate('rosedaleBanner')}</p>
                    </div>
                    <img className='max-w-[400px] max-h-[400px]' src={rosedaleHousing} alt='parker filters' />
                    <img className='max-w-[400px] max-h-[400px] min-h-[400px]' src={rosedaleLogo} alt='parker logo' />
                </div>
            </Container>
            <Container className='flex lg:flex-row flex-col items-center justify-around mt-10 mb-10'>

                <div className='flex flex-col items-center text-center max-w-[600px] ml-5 mr-5'>
                    <h1 className='m-5 lg:text-[1.7em]'>{translate('locationTitle')}</h1>
                    <p className='text-2xl whitespace-pre-line mb-5'>
                        {translate('locationBody')}
                    </p>
                </div>
                <Map className='grow pl-10 pr-10 h-[450px] min-w-[85vw] lg:min-w-[50vw]' />

            </Container>
        </div>
    );
};
export default Home;