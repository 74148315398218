import { useState, useRef } from "react";
import { AiOutlinePhone, AiOutlineMail } from 'react-icons/ai';
import { MdLocationPin } from 'react-icons/md';
import emailjs from '@emailjs/browser';


import classNames from "classnames";
import Container from "../components/Container";
import Map from "../components/Map";
import Modal from "../components/Modal";

import useLanguage from "../hooks/use-language";



function Contact() {

    const { translate } = useLanguage()


    const form = useRef();


    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('')
    const [company, setCompany] = useState('');
    const [message, setMessage] = useState('');

    const [showModal, setShowModal] = useState(false);

    const handleClose = () => {
        setShowModal(false);
    };

    const handleNameChange = (e) => {
        setName(e.target.value);
    }

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    }

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    }

    const handleCompanyChange = (e) => {
        setCompany(e.target.value);
    }

    const handleMessageChange = (e) => {
        setMessage(e.target.value);
    }

    const labelClasses = classNames(
        'flex flex-col m-2 p-2'
    );

    const inputClasses = classNames(
        'border rounded-md mt-1 bg-gray-50 p-2'
    );

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_cbf8hyj', 'template_y3hq8v8', form.current, '3UN4XLKXeNkW4ROlB')
            .then((result) => {
                setName('');
                setPhone('');
                setEmail('');
                setCompany('');
                setMessage('');
                setShowModal(true);
                console.log(result.text);
                console.log('SUCCESS, EMAIL SENT')
            }, (error) => {
                console.log(error.text);
            });
    };


    const modal = 
    <Modal onClose={handleClose}>
        {translate('modalMessage')}
    </Modal>

    return (
        <div className='flex-1 flex-col max-w-[100vw]'>
            <Container className='flex-1 flex-col items-center min-h-[5vh] max-h-[200px] max-w'>
                <h1 className='m-4 text-center'>{translate('contactUsTitle')}</h1>
                <h4 className='ml-5 mr-5 text-center'>{translate('contactUsBody')}</h4>
            </Container>
            <div className="flex flex-col lg:flex-row mt-10 justify-around max-w-[100vw]">
                <div className='w-[90%] m-2 grow md:pl-10 md:pr-10 pl-5'>
                    <h2>{translate('ourContactTitle')}</h2>
                    <div className='flex flex-row items-center h-[5vh]'><MdLocationPin className='text-2xl' /><span className='justify-center whitespace-pre-wrap text-[1.3vh] md:text-[1.7vh]'>{translate('ourContactAddress')}</span></div>
                    <div className='flex flex-row items-center h-[5vh]'><AiOutlinePhone className='text-2xl' /><span className='justify-center whitespace-pre-wrap text-[1.3vh] md:text-[1.7vh]'>{translate('ourContactTelephone')}</span></div>
                    <div className='flex flex-row items-center h-[5vh]'><AiOutlineMail className='text-2xl' /><span className='justify-center whitespace-pre-wrap text-[1.3vh] md:text-[1.7vh]'>{translate('ourContactEmail')}</span></div>
                    <Map className='mt-2 hidden md:flex h-[40vh] lg:h-[60%]'/>
                </div>
                <form ref={form} onSubmit={sendEmail} className='flex flex-col lg:w-[50vw] m-7 pl-5 pr-5'>
                    {translate('formTitle')}
                    <label className={labelClasses}>
                    {translate('formName')}
                        <input type='text' name='name' className={inputClasses} value={name} onChange={handleNameChange}></input>
                    </label>
                    <label className={labelClasses}>
                    {translate('formEmail')}
                        <input type='email' name='email' className={inputClasses} value={email} onChange={handleEmailChange} placeholder="email@domain.com"></input>
                    </label>
                    <label className={labelClasses}>
                    {translate('formPhone')}
                        <input type='tel' name='phone' className={inputClasses} value={phone} onChange={handlePhoneChange} placeholder="514-999-9999"></input>
                    </label>
                    <label className={labelClasses}>
                    {translate('formBusiness')}
                        <input type='text' name='company' className={inputClasses} value={company} onChange={handleCompanyChange}></input>

                    </label>
                    <label className={labelClasses}>
                    {translate('formMessage')}
                        <textarea name='message' className={inputClasses} value={message} onChange={handleMessageChange}></textarea>

                    </label>

                    <input
                        type="submit"
                        value={translate('formSubmit')}
                        className='
                    transition 
                    ease-in-out 
                    delay-50 
                    bg-blue-500 
                    hover:-translate-y-1 
                    hover:scale-110 
                    hover:bg-indigo-500 
                    duration-300 
                    max-w-[100px] 
                    max-h-[50px] 
                    rounded-md 
                    p-2 
                    m-3
                    text-white
                    self-end'
                    >
                    </input>
                </form>

            </div>
            {showModal && modal}


        </div>
    );
}
export default Contact;