import classNames from "classnames";

function Container({className, children, ...rest}){


    const containerClasses = classNames(
        'max-w-[100vw]',
        className

    );



    return (
        <div 
        className={containerClasses}
        {...rest}
        >{children}</div>
    );
};

export default Container;