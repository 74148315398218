import classNames from 'classnames';

function Button({children, className, ...rest}){

    const classes = classNames(
        'flex-grow w-100 h-100', 
    className,
    );

    return (
        <button className={classes} {...rest}>
            {children}
        </button>
    );
}
;

export default Button;
