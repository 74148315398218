import ReactDOM from "react-dom";
import { useEffect } from "react";


function Modal({ onClose, children, actionBar }) {
    useEffect(() => {
        document.body.classList.add('overflow-hidden'); // tailwind class
        return () => {
            document.body.classList.remove('overflow-hidden');
        };
    }

        , []);
    return ReactDOM.createPortal( //This is a 2 argument function, what to put, then where to put it in index.html
        <div>
            <div onClick={onClose} className="fixed inset-0 bg-gray-300 opacity-80"></div>
            <div onClick={onClose} className="fixed inset-y-[25vh] inset-x-[20vw] p-2 bg-white w-[60vw] h-40[vh] sm:h-[25vh] md:h-[20vh] lg:h-[15vh] rounded">
                <div className="flex flex-col place-content-center text-center h-full whitespace-pre-line">
                    <>
                        {children}
                    </>

                    <div className="flex">
                        {actionBar}
                    </div>
                </div>

            </div>
        </div>,
        document.querySelector('.modal-container')
    );
}
export default Modal;